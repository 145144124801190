@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProRegular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProMedium.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro - Mediumitalic';
  src: url('./assets/fonts/SofiaPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProSemiBold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProBlack.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'SofiaPro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d282e;
  color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --green: #4cffb3;
  --purple: #a071ee;
  --pink: #d355e7;
  --gold: #fedd5b;
  --blue: #00cbff;
  --dark-grey: #2a3d42;
  --darkest-grey: #21383d;
  --medium-grey: #7d9fb1;
  --medium-grey-blue: #0a282e;
  --grey: #bfcbd0;
  --light-grey: #fbfbfb;
  --black: #000000;
  --white: #fff;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 27px;
  }
}
