.card-list-container {
  p {
    margin: 0;
    padding: 0;
  }

  display: grid;
  grid-template-areas:
    'image header'
    'image body';
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto auto;
  border-radius: 4px;
  overflow: hidden;

  .card-image-container {
    background-color: var(--dark-grey);
    padding: 20px 12px;
    grid-area: image;
  }

  .token-image {
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--white);

    > img {
      height: 102%;
      width: 102%;
      object-fit: cover;
    }
  }

  .card-header {
    display: flex;
    min-width: 0;
    background-color: var(--medium-grey-blue);
    padding: 6px 24px;

    .card-header-content {
      display: flex;
      grid-area: header;
      align-items: center;
      column-gap: 8px;
      min-width: 0;
      width: 100%;
    }

    .navigate-button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--pink);
      align-self: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 6px;
    }

    .infos-container {
      display: flex;
      flex-direction: column;
      min-width: 0;
      align-items: flex-start;
    }

    .collection-image-container {
      .collection-image {
        width: 31px;
        height: 31px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #132124;

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .collection-name-mobile {
        display: none;
        color: var(--medium-grey);
        font-size: 14px;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .token-name {
      font-size: 18px;
      color: var(--white);
      font-weight: 700;
      margin-bottom: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .secondary-infos {
      display: flex;
      column-gap: 22px;
      min-width: 0;
      margin-right: 12px;

      p,
      span {
        font-size: 14px;
        font-weight: 500;
        color: var(--medium-grey);
      }

      > div {
        display: flex;
        min-width: 0;

        .token-id {
          margin-left: 4px;
        }
      }
    }
  }

  .card-body {
    grid-area: body;
    display: flex;
    background-color: var(--darkest-grey);
    padding: 12px 24px;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 8px;

    .no-attributes {
      color: var(--medium-grey);
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-areas: 'image header';

    .token-image {
      width: 90px;
      height: 90px;
      border-radius: 4px;
      background-color: var(--white);
    }

    .card-image-container {
      background-color: var(--darkest-grey);
      padding: 8px;
    }

    .card-body {
      display: none;
    }

    .card-header {
      align-items: center;
      background-color: var(--darkest-grey);

      .card-header-content {
        display: block;
        align-items: flex-start;
        padding: 0 8px;

        .token-name {
          font-size: 16px;
          margin-bottom: 2px;
        }

        .secondary-infos {
          .collection-name {
            display: none;
          }
        }

        .collection-image-container {
          display: flex;
          align-items: center;
          column-gap: 10px;

          .collection-name-mobile {
            display: block;
          }
        }
      }
    }
  }
}
