.nft-title {
  background: var(--darkest-grey);
  border-radius: 2.4px;
  height: auto;
  padding: 12px;
  font-size: 18px;
  letter-spacing: 0;
  border-left: 4px solid var(--pink);
  display: flex;
  align-items: center;

  .id {
    font-size: 16px;
    font-weight: 300;
    color: var(--medium-grey);

    .number {
      color: var(--pink);

      &:before {
        content: ' #';
      }
    }
  }
}

.nft-information-container {
  margin: 50px 0;

  .image-container {
    display: flex;
    margin-right: 10px;

    .token-image {
      width: 580px;
      height: 580px;
      position: relative;
      border-radius: 5px;
      object-fit: contain;
    }

    .magnify-zoom-icon {
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: 2px;
      right: 2px;
    }

    .image-content {
      height: 580px;
      position: relative;
    }
  }

  .info-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1px;
  }
}

.description-block {
  @extend .verti;
  background-color: var(--darkest-grey);
  margin: 5px 2px 0 2px;
  padding: 5px 10px 15px 5px;
  border-radius: 5px;
  min-height: 140px;

  .title {
    color: var(--medium-grey);
    font-size: 12px;
    margin: 0 5px;
  }

  .content {
    display: flex;
    font-size: 18px;
    font-weight: 300;
    color: var(--white);
    margin: 20px 10px;
    align-items: center;
    align-self: center;
    height: 100%;
  }

  .inline-content {
    font-size: 16px;
    font-weight: 300;
    color: var(--pink);
    display: table;
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
    margin: 20px 5px;
  }

  .grid-content {
    display: flex;
    font-size: 18px;
    font-weight: 300;
    color: var(--white);
    margin: 20px auto 0 auto;
    align-items: flex-end;
  }

  .token-logo {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .collection-logo {
    @extend .token-logo;
    border-radius: 9999px;
  }
}

.content-block {
  @extend .description-block;
  min-height: 70px;
}

.nft-attributes-container {
  .attributes-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 1px;
  }

  .attributes-block {
    @extend .description-block;
    min-height: auto;

    .content {
      word-break: break-all;
    }
  }
}

.section-title {
  margin: 0 0 5px 2px;
}

.title-container {
  display: flex;

  h1 {
    letter-spacing: -1.27px;
    margin: 48px 0;
    font-weight: 500;
  }

  svg {
    margin-bottom: 6px;
    margin-right: 12px;

    g {
      fill: var(--white);
    }
  }
}

.modal-container {
  outline: none;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

.modal-content {
  outline: none;
  height: 100%;
  font-weight: 300;

  .content-position {
    width: 100%;

    &:first-of-type {
      text-align: right;
    }

    &:last-of-type {
      text-align: left;
      margin-top: 10px;
    }
  }

  .content-image {
    display: flex;
    height: 80%;
    width: auto;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }

  button {
    background-color: transparent;
    border: none;
    color: var(--white);
  }
}

.verti {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) {
  .nft-title {
    flex-direction: column;
    align-items: start;
    font-size: 25px;
    margin-bottom: 15px;

    .id {
      font-size: 25px;
      display: block;
      margin-top: 10px;
    }
  }

  .description-block {
    height: auto;
    .title {
      font-size: 20px;
      padding-top: 5px;
    }
    .content {
      font-size: 20px;
      text-align: left;
      align-self: flex-start;
    }
    .inline-content {
      font-size: 20px;
      align-self: center;
      margin: 15px;
    }
    .grid-content {
      font-size: 20px;
      margin: 5px 10px;
      text-align: right;
      align-items: center;
      align-self: flex-end;
    }
    .token-logo {
      width: 40px;
      height: 40px;
    }
  }

  .section-title {
    margin-bottom: 30px;
    font-size: 25px;

    &:after {
      content: '';
    }
  }

  .content-block {
    height: auto;

    .title {
      padding-bottom: 10px;
    }
  }

  .nft-information-container {
    .info-grid {
      grid-template-columns: 1fr;
    }

    .image-container {
      justify-content: center;
      margin: 0 0 30px 0;

      .token-image {
        max-width: 100%;
        height: 380px;
      }

      .image-content {
        width: 100%;
        height: 380px;
      }
    }
  }

  .nft-attributes-container {
    .attributes-grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .attributes-block {
      .title {
        font-size: 20px;
        margin: 5px;
      }
      .content {
        font-size: 20px;
        margin: 0 10px;
        align-self: end;
      }
    }
  }

  .modal-content {
    margin: 0 15px;

    img {
      max-width: 100%;
    }
  }
}
