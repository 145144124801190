.mobile-contract-invocations {
  .data-list {
    span:nth-last-child(3) {
      border-bottom: 1px solid #445e65 !important;
    }
  }
}

#ContractInvocations {
  padding: 6px 24px;
  background: #2a3d42;

  .data-list {
    grid-row-gap: 0px;

    .data-list-column {
      background: #2a3d42;
      min-height: 48px;
      border-bottom: 1px solid #445e65;
      padding-top: 12px;
    }

    span {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #445e65;
    }

    span:last-child,
    span:nth-last-child(2),
    span:nth-last-child(3) {
      border-bottom: none;
    }
  }

  .invocation-count-bar {
    background: #0d292f;
    border-radius: 5.94px;

    width: 450px;
    height: 8px;

    .invocation-count-bar-count-color {
      background: #d355e7;
      border-radius: 5.94px;
      height: 8px;
    }
  }

  .invocation-count {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
  }

  .invocation-change {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 1.09px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
    }
  }

  .invocation-name-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 12px;
    text-decoration: none;
    color: #ffffff;

    .invocation-name {
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      margin-right: 24px;
      width: 175px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .invocation-position {
      background: #0d292f;
      min-height: 21px;
      min-width: 24px;
      height: 21px;
      width: 24px;
      border-radius: 22px;
      padding-top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #ContractInvocations {
    .invocation-count,
    .invocation-change,
    .invocation-name {
      font-size: 11px !important;
      // width: 200px;
    }
  }

  #ContractInvocations .invocation-name-container .invocation-name {
    width: 100%;
  }

  #ContractInvocations .invocation-name-container .invocation-position {
    margin-right: 12px;
  }

  #ContractInvocations .data-list > a,
  .data-list > #non-link-list-cell-container {
    padding: 0 12px;
  }

  #ContractInvocations .data-list-column {
    padding: 0 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .invocation-count-bar {
    width: 250px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .invocation-count-bar {
    display: none;
  }
  #ContractInvocations .invocation-name-container {
    justify-content: flex-start;
  }
}
