#SearchResults {
  a {
    text-decoration: none;
    color: #fff;
  }

  .search-results-title {
    svg {
      height: 27px;
      width: 27px;
    }
  }

  .results-explanation {
    font-size: 22px;
    font-weight: 300;
    display: flex;
    margin-bottom: 48px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .results {
      color: #d355e7;
      margin-left: 6px;
    }
  }

  .search-result-container {
    min-height: 90px;
    height: 120px;
    max-height: 120px;
    width: 100%;
    margin-bottom: 24px;
    display: flex;

    .search-result-chain-info {
      min-height: 90px;
      height: 90px;
      background-color: #27454c;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      #chain-icon {
        background-color: rgb(30, 52, 56);
        padding: 12px;
        border-radius: 100%;
        display: flex;
      }

      svg {
        height: 30px;
        width: 30px;
      }

      :first-letter {
        text-transform: capitalize;
      }

      p {
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 13px;
      }
    }

    .search-results-details {
      display: flex;
      flex-direction: column;
      width: 100%;

      .search-result-type {
        background-color: #21383d;
        font-size: 24px;
        width: 100%;
        flex: 1;
        align-items: center;
        display: flex;

        svg {
          max-height: 24px;
          max-width: 24px;
          min-height: 24px;
          min-width: 24px;
          margin: 0 12px 0 24px;

          g {
            fill: #d355e7;
          }
        }
      }

      .search-result-info {
        background-color: #1e3438;
        width: 100%;
        display: flex;
        flex: 1;
        height: 100%;

        .search-result-detail {
          margin: 0 12px;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding-left: 12px;

          label {
            font-size: 12px;
            color: #7d9fb1;
            letter-spacing: 0;
          }

          span {
            padding-top: 2px;
          }
        }
      }
    }
  }
}
