#navigation-container {
  height: 88px;
  background-color: #0d282e;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;

  #desktop-navigation {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    flex: 1 1;
    width: 100%;
    max-width: 1200px;
    margin: auto;

    #inner-desktop-navigation-container {
      padding: 0 12px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    #coz-blockchain-logo {
      margin-right: 60px;
      margin-top: -6px;
    }

    .navigation-search-container {
      width: 100%;
      display: flex;
      flex: 1;
    }
  }

  #mobile-navigation {
    display: none;
  }
}

.mobile-navigation-search-container {
  display: none;
}

@media only screen and (max-width: 768px) {
  #navigation-container {
    height: 53px;
    align-items: center;
    justify-content: center;

    #desktop-navigation {
      display: none;
    }

    #mobile-navigation {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;

      #mobile-logo-container,
      #burger-menu-container {
        cursor: pointer;
      }

      #top-level-mobile-navigation {
        display: flex;
      }
    }

    #navigation-search-container {
      display: none;
    }
  }

  .mobile-navigation-search-container {
    width: 100%;
    justify-content: center;
    display: flex;
    flex: 1;
    margin: auto;
    max-height: 50px;
    margin-top: 24px;

    form {
      margin: auto;
    }

    #Search {
      justify-content: center;
      padding: 0 12px;
    }
  }
}

#mobile-routes-container {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  outline: none;
  justify-content: center;

  .mobile-route-container {
    height: 145px;
    width: 145px;
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 8.9px;
    color: #899fa8;
    cursor: pointer;
    margin-bottom: 12px;
    text-decoration: none;
    border: solid 1px #899fa8;
    margin: 24px 12px;
    border-radius: 3px;
    svg {
      margin-bottom: 12px;
    }
  }

  .active-mobile-route,
  .mobile-route-container:hover {
    background-color: #0d282e;
    text-decoration: none;
    border: solid 1px #d355e7;
    color: #d8d8d8;

    svg {
      g {
        fill: #d8d8d8;
      }
    }
  }
}

.bm-overlay-background,
.bm-overlay {
  background: #263a3e !important;
  transition: opacity 0.3s ease 0s !important;
}

.bm-burger-button {
  display: none;
}

#mobile-routes-container {
  transition: opacity 0.3s ease 0s !important;
}
