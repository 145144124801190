.pagination div {
  ol li button {
    color: #cae0eb !important;

    &[aria-current='page'] {
      background-color: #d355e7 !important;
      color: white !important;

      &:focus {
        box-shadow: 0 0 0 2px white, 0 0 0 4px #d355e7 !important;
        background-color: #d355e7 !important;
        color: white !important;
      }
    }

    &:hover {
      color: #21383d !important;
    }

    &:focus {
      box-shadow: 0 0 0 2px #21383d, 0 0 0 4px white !important;
      background-color: white !important;
      color: #21383d !important;
    }
  }

  button {
    span svg g path {
      fill: #cae0eb !important;
    }
    &:focus {
      span svg g path {
        fill: #21383d !important;
        box-shadow: 0 0 0 2px #21383d, 0 0 0 4px white !important;
      }
    }
    &:hover {
      span svg g path {
        fill: #21383d !important;
      }
    }
  }
}
