#footer-container {
  display: flex;
}

#Footer {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  padding: 24px 0;
  margin: auto;

  max-width: 1200px;

  .accent {
    color: var(--pink);
  }

  #footer-body {
    display: flex;
    padding: 12px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 12px;
    }

    // common classes
    .section-container {
      flex: 1;
      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }

    .divider {
      border: 0.5px solid var(--medium-grey);
      width: 100%;
      margin: 0 0 30px 0;
    }

    .first-section,
    .second-section {
      padding-right: 16px;
      padding-top: 5px;
    }

    @media only screen and (max-width: 768px) {
      .first-section {
        padding-top: 24px;
      }
    }

    .footer-sections {
      display: flex;
      flex-wrap: wrap;

      .third-section {
        min-width: 230px;
      }
    }

    // Col 1
    .footer-col-1 {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 64px;

      .neoscan-logo-container {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
      }

      .neoscan-copy {
        font-size: 31.68px;
        letter-spacing: 3.39px;
        margin-left: 16px;
      }

      .license-copy {
        max-width: 225px;
        line-height: 20px;

        .own-line {
          display: block;
        }
      }
    }

    // Links/Nav
    .links-list {
      list-style: none;
      padding-left: 0;
      @media screen and (max-width: 768px) {
        columns: 2;
        margin-bottom: 24px;
        li:first-child {
          margin-top: 0;
        }
      }
      li {
        margin: 12px 0;
      }
      a {
        text-decoration: none;
        color: var(--white);
      }
    }

    .neoscan-description {
      margin-bottom: 24px;
      color: #96b4c4;
    }
    .info-links-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        margin-bottom: 24px;
      }

      a.accent {
        display: flex;
        align-items: flex-end;
        width: 135px;
        margin: 0 4px 12px 0;

        .icon {
          padding-right: 14px;
        }
      }
    }

    // News stream

    .label-container {
      display: flex;

      img {
        padding-right: 6px;
        align-self: center;
        padding-bottom: 1px;
      }
    }
    .news-stream {
      display: flex;
      flex-direction: column;

      .news-item {
        display: flex;
        width: 100%;
        height: 48px;

        img {
          height: 48px;
          width: 48px;
          padding-right: 12px;
        }

        p {
          margin: 0;
        }
      }

      button {
        font-size: 14px;
        border: none;
        align-self: flex-end;

        @media screen and (max-width: 768px) {
          margin: 0 auto;
        }
      }
    }
  }

  // Logo/Copyright
  #company-banner {
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 65px;

    @media screen and (max-width: 768px) {
      padding: 12px;
      margin-top: 3px;
    }
    .coz-logo {
      margin-right: 16px;
    }
    .copyright {
      align-self: flex-end;
      display: inline-block;
      margin-bottom: 4px;
    }
    .last-commit {
      @media screen and (max-width: 768px) {
        padding: 0 12px 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .license-copy {
    max-width: 275px !important;
  }
  .footer-sections {
    flex-direction: column;

    .first-section,
    .second-section {
      padding-right: 0px !important;
    }
  }
}
