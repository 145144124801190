.transfer-container {
  margin-bottom: 24px;
  display: flex;
  background: transparent;
  flex-wrap: wrap;

  @media only screen and (max-width: 1030px) {
    flex-direction: column;

    .detail-tile {
      width: 100%;
    }

    #transfer-arrow-container {
      margin: 24px 0;
    }

    .transfer-column {
      padding: 12px 0;

      label {
        padding-left: 12px;
      }
    }
  }

  .tx-cube-image {
    position: absolute;

    left: 10px;
    margin-left: 370px;
    margin-top: 50px;

    @media only screen and (max-width: 1270px) {
      margin-left: 360px;
    }

    @media only screen and (max-width: 1250px) {
      margin-left: 340px;
    }

    @media only screen and (max-width: 1220px) {
      margin-left: 320px;
    }
    @media only screen and (max-width: 1180px) {
      margin-left: 300px;
    }

    @media only screen and (max-width: 1130px) {
      margin-left: 280px;
    }
    @media only screen and (max-width: 1080px) {
      margin-left: 260px;
    }
    @media only screen and (max-width: 1030px) {
      margin-left: 50%;
    }
  }

  .sent-to-cube {
    margin-left: 0;
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);

    @media only screen and (max-width: 1030px) {
      margin-left: 50%;
    }
  }

  label {
    font-size: 12px;
    color: #7d9fb1;
    letter-spacing: -0.48px;
    margin-bottom: 12px;
    align-self: flex-start;
  }
}

.link {
  font-weight: 500;
  font-size: 14px;
  color: #d355e7;
  letter-spacing: 0;
  cursor: pointer;
}

.link-pink {
  font-weight: 500;
  font-size: 14px;
  color: #d355e7;
  letter-spacing: 0;
  cursor: pointer;
}

#transfer-arrow-container {
  margin: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 150px;

  img {
    margin: -18px 0 -108px 0;
    overflow: hidden;
  }

  svg {
    margin: 12px 0 18px 0;
  }

  div {
    width: 151px;
    display: flex;
    align-items: baseline;
    margin: 1px 0;
    justify-self: center;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    color: #7d9fb1;
    letter-spacing: -0.48px;
    line-height: 18.17px;
    width: 80px;
    text-align: left;
  }
}

.transfer-column {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  flex: 1;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border: 0 solid #25393e;
  height: auto !important;
  max-height: none !important;
}

.asset-transfer-details-container {
  margin: 0 0 24px 0;
}

.asset-transfer-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.transfer-amount-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  div {
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 10px;
    margin-top: -2px;
    max-width: 20px;
  }
}

.transfer-amount-container :nth-child(2) {
  margin-right: 4px;
  margin-left: 4px;
}

@media only screen and (max-width: 810px) {
  .transfer-container {
    flex-direction: column;
  }

  #chevron-right-transfer {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .transfer-container {
    a {
      font-size: 14px;
    }
  }
}
