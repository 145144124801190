#Monitor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1150px;
  width: 100%;
  margin: auto;
}

.network-status-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 100%;
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 50px;
  align-items: stretch;
}

.network-status-header {
  height: 47px;
  background-color: #ffffff07;
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.network-status-title {
  color: #ffffff;
  font-size: 24px;
  padding-top: 5px;
  padding-left: 11px;
}

.network-status-content {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.list-container {
  padding: 7px;
}

.page-title-container {
  display: flex;
  align-items: center;

  h1 {
    letter-spacing: -1.27px;
    margin: 48px 0;
    font-weight: 500;
  }

  svg {
    margin-bottom: 6px;
    margin-right: 12px;

    g {
      fill: #fff;
    }
  }
}

.endpoint {
  display: flex;

  align-items: center;
  margin-left: -15px;

  min-width: 250px;
  width: 250px;
  margin-left: 2px;

  .endpoint-url {
    width: 215px;
    min-width: 215px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  span {
    margin-top: 2px;
  }
}

.endpoint-flag-container {
  margin-right: 7px;
}

.disable {
  color: #7d9fb1;
}

.disableLink {
  color: #7d9fb1;
  cursor: pointer;
}

.disableLink:hover {
  color: #7d9fb1;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.with-arrow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  cursor: pointer;
  color: #fff;
}

.with-arrow:hover {
  color: #fff;
}

.link {
  color: #fff;
  cursor: pointer;
}

.link:hover {
  color: #fff;
}

.copy-clipboard {
  width: 350px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #00000099;
  border-radius: 15px;
}

.endpoint-copy-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-right: 20px;
}

.data-list-column {
  padding: 4px 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  background-color: var(--medium-grey-blue);
  min-height: 25px;
}

.without-pointer-cursor {
  cursor: default !important;
}

.list-header-and-pagination-info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.data-list > #non-link-list-cell-container {
  padding: 0px 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 45px;
  background-color: var(--dark-grey);
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--white);
}

.data-list {
  display: grid;
  grid-row-gap: 8px;
}

@media screen and (max-width: 768px) {
  .data-list > span,
  .data-list-column,
  .txid-index-cell {
    font-size: 11px;
  }
}

.loading-table-row {
  opacity: 0.75;
}

#Monitor {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .information-panel-container {
    margin: 2px;
    margin-bottom: 50px;
  }

  .network-status-content :first-child {
    margin-left: 0;
  }
  .network-status-content :last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 1300px) {
    .information-panel-container {
      width: 100%;
      margin: 2px;
      min-width: 200px;
      margin-bottom: 50px;

      .information-panel-data {
        height: 100%;
        margin-top: -24px;
      }

      h1 {
        font-size: 22px;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .endpoint {
      min-width: 160px;
      width: 160px;

      .endpoint-url {
        font-size: 12px;
        min-width: 160px;
      }
    }
  }

  @media only screen and (max-width: 850px) {
    .network-status-content {
      flex-direction: column;

      .information-panel-container {
        margin: 2px 0;
      }
    }

    .data-list-container {
      margin-top: 50px;
    }
  }

  @media only screen and (max-width: 768px) {
    .endpoint {
      min-width: 100px;
      width: 100px;

      .endpoint-url {
        font-size: 10px;
        min-width: 100px;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .endpoint {
      min-width: 100px;
      width: 100px;

      .endpoint-url {
        font-size: 10px;
        min-width: 70px;
      }
    }
  }

  @media only screen and (max-width: 710px) {
    .select-container {
      align-items: center;
      max-height: 30px;
    }
  }
}

@media only screen and (max-width: 975px) {
  .network-status-content {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .page-title-container {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .network-status-header {
    flex-wrap: wrap;
    flex-direction: column;
    height: fit-content;
    align-items: stretch;
  }

  .network-status-toggle {
    padding: 13px 17px 17px 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
