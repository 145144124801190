.sidebar-spacer {
  width: 80px;
  min-width: 80px;
}

#sidebar-container {
  width: 80px;
  background-color: #263a3e;
  position: fixed;
  height: 100%;
  z-index: 1200;
}

.sidebar-route-container {
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 8.9px;
  color: #899fa8;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  border-right: solid 3px transparent;

  svg {
    margin-bottom: 12px;
  }
}

.sidebar-route-container-img {
  margin-bottom: 12px;
}

.active-sidebar-route,
.sidebar-route-container:hover {
  background-color: #0d282e;
  text-decoration: none;
  border-right: solid 3px #d355e7;
  box-sizing: border-box;

  color: #d8d8d8;

  svg {
    g {
      fill: #d8d8d8;
    }
  }
}

#sidebar-logo-container {
  height: 88px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    margin-top: 12px;
  }

  p {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 1.5px;
    text-decoration: none;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 768px) {
  #sidebar-container {
    display: none;
  }

  .sidebar-spacer {
    display: none;
  }
}
