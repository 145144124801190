.information-panel-container {
  background-color: #25393e;
  border-radius: 2px;
  max-width: 398px;
  min-width: 33%;
  height: 142px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.information-panel-header {
  align-self: flex-start;
  flex-direction: column;
  padding-left: 11px;
}

.information-panel-title {
  font-weight: 900;
  font-size: 11px;
  color: #7d9fb1;
  font-family: 'Sofia Pro - Black' sans-serif;
  letter-spacing: -0.44px;
  line-height: 38.01px;
  align-self: flex-start;
  text-align: left;
  text-transform: uppercase;
}

.information-panel-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 15px;

  h1 {
    letter-spacing: -1.27px;
    font-weight: 500;
  }

  svg {
    margin-right: 12px;

    g {
      fill: #fff;
    }
  }
}

@media only screen and (max-width: 975px) {
  .information-panel-container {
    width: 100%;
    max-width: none;
  }
}
