#nft-filters-container {
  display: flex;
  justify-content: flex-end;
  margin: 35px 0;
  column-gap: 16px;

  button {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
