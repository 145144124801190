#Blocks {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.neo3-platform-cell {
  font-size: 16px;
  color: #88ffad;
  letter-spacing: 0;
  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
  }
}

.neo2-platform-cell {
  font-size: 16px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  color: #b0eb3c !important;

  img {
    margin-right: 12px;
  }
}

.page-title-container {
  display: flex;
  align-items: center;

  h1 {
    letter-spacing: -1.27px;
    margin: 48px 0;
    font-weight: 500;
  }

  svg {
    margin-bottom: 6px;
    margin-right: 12px;

    g {
      fill: #fff;
    }
  }
}

.list-wrapper {
  padding: 0 12px;
  width: 100%;
  max-width: 1200px;
}

.block-list-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
}

.load-more-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  button {
    height: 31px;
    width: 174px;
  }
}

@media screen and (max-width: 768px) {
  .page-title-container {
    margin: 0;
  }
}
