.neoscan-button {
  border-radius: 13.5px;
  height: 27px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: -0px;
  border: none;
  padding: 0 12px;
  cursor: pointer;
}

.neoscan-button:focus {
  outline: 0;
}

.primary-button {
  background: #eb4dff;

  &:hover {
    background: #dc6fed;
  }

  &:disabled {
    background: #724579;
  }
}

.secondary-button {
  background: #7d9fb1;

  &:hover {
    background: #96c0d5;
  }

  &:disabled {
    background: #4b575e;
  }
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}
