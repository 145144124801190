.multi-chain-dashboard-list {
  h4 {
    margin-top: 0;
  }

  .list-wrapper,
  .explore-blocks {
    margin: 12px;
  }

  .block-lists {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .block-list-chain-container {
    margin: 12px;
    width: 575px;
  }

  @media only screen and (max-width: 1300px) {
    .block-list-chain-container {
      width: 100%;
      max-width: 100% !important;
      display: flex;
    }

    .DashboardBlockList {
      .list-wrapper,
      .block-list-chain-container {
        max-width: 100% !important;
      }
    }
  }
}

.multi-chain-dashboard-list.list-row-container {
  justify-content: space-between !important;
}

.label-wrapper-2 {
  padding: 10px 0;
}
