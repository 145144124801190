#Breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .crumb-container {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #d355e7;
  }

  .active-breadcrumb {
    color: #7d9fb1;
    cursor: default;
  }
}
