#Search {
  display: flex;
  width: 100%;
  max-height: 200px;

  form {
    display: flex;
    align-items: center;
    display: flex;
    max-width: 440px;
    width: 100%;
  }

  input {
    background: #2f454e;
    font-size: 12px;
    color: #7591a1;
    font-weight: 600;
    letter-spacing: 0.82px;
    border: none;
    width: 100%;
    height: 31px;
    border-radius: 80px;
    padding: 0 36px 0 24px;
    outline: none;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-left: -30px;
    margin-right: 12px;
    cursor: pointer;
  }

  input:focus {
    text-overflow: ellipsis;
  }

  input::placeholder {
    color: #7591a1;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
